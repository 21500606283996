<template>
  <modal width="30%" :show="show" :title="title">
    <template v-slot>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-4">
          <v-text-field
            label="Field Name"
            type="text"
            v-model="fieldName"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="ma-1">
        <v-col class="ma-0 pa-4">
          <v-text-field
            label="Field Value"
            type="text"
            v-model="fieldValue"
            outlined
            dense
            hide-details
          ></v-text-field> </v-col
      ></v-row>
      <v-row class="ma-1">
        <v-col class="ma-0 pa-4">
          <!-- <v-text-field
            label="Field Type"
            type="text"
            v-model="fieldType"
            outlined
            dense
            hide-details
          ></v-text-field>  -->
          <v-select
            :items="fieldTypeItems"
            label="Field Type"
            v-model="fieldType"
          ></v-select> </v-col
      ></v-row>
    </template>
    <template v-slot:actions>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-1 pa-0">
          <v-btn
            class="elevation-5"
            color="success"
            text
            @click="createField"
            block
          >
            Create
          </v-btn>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-1 pa-0">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            @click="closeModal"
            block
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </template>
  </modal>
</template>

<script>
import { DateTime } from "luxon";
import Modal from "@/layout/Modal.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    Modal,
  },
  props: ["show", "title", "organization_id", "details"],
  data() {
    return {
      fieldName: "",
      fieldValue: "",
      fieldType: "",
      DateTime,
      fieldTypeItems: [
        "DATE",
        "DATETIME",
        "CURRENCY",
        "NUMBER",
        "BOOLEAN",
        "STRING",
      ],
    };
  },
  methods: {
    ...mapActions(["createCustomFieldForOrganization"]),
    closeModal() {
      this.cleanData();
      this.$emit("showModal");
    },
    async createField() {
      const data = {
        organization_id: this.organization_id,
        fieldName: this.fieldName,
        fieldValue: this.fieldValue,
        fieldType: this.fieldType,
      };
      const response = await this.createCustomFieldForOrganization({
        data,
      });

      console.log("create custom field response", response);

      if (response) {
        this.cleanData();
        this.$emit("showModal");
      }
    },
    cleanData() {
      this.fieldName = "";
      this.fieldValue = "";
      this.fieldType = "";
    },
  },
  created() {},
};
</script>

<style scoped>
.uploadFile {
  height: 30%;
  position: relative;
}

.uploadFile:hover {
  border: 1px solid #2e94c4;
}

.uploadFile input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
@media only screen and (min-width: 1264px) {
  .upload-top {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1500px) {
  .upload-top {
    max-width: 60%;
  }
}
</style>
