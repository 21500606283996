<template>
  <v-row dense class="justify-end align-center elevation-5 pa-3 ma-0 dark-blue">
    <v-col xl="6" md="6" class="pa-0 ma-0">
      <v-text-field
        class="ma-0"
        hide-details
        type="text"
        outlined
        dense
        clearable
        :prepend-inner-icon="'mdi-magnify'"
        v-model="searchInput"
      >
        <template v-slot:label
          ><span class="pa-0 ma-0"> Search </span>
        </template>
      </v-text-field>
    </v-col>

    <v-col cols="6" class="pa-0 ma-0 d-flex justify-end">
      <v-col cols="2">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on }">
            <v-btn color="dark-blue" class="lighten-2" v-on="on" block>
              Export
              <v-icon dark>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Export data</span>
        </v-tooltip>
      </v-col>
      <v-col cols="3">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on }">
            <v-btn
              color="dark-blue"
              class="lighten-2"
              block
              v-on="on"
              @click="$emit('showSmallTable', { flag: true })"
            >
              New Organization

              <!-- <v-icon dark>mdi-plus</v-icon> -->
            </v-btn>
          </template>
          <span>Add New Organization</span>
        </v-tooltip>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      searchInput: "",
    };
  },
  watch: {
    searchInput() {
      this["moduleOrganizations/setSearchInput"](this.searchInput);
    },
  },
  methods: {
    ...mapMutations(["moduleOrganizations/setSearchInput"]),
  },
};
</script>

<style></style>
