<template>
  <v-row class="overflow-y-auto fill-height">
    <slot :name="`col-header-name`"></slot>

    <template v-for="f in fields">
      <v-col :key="f.value" :cols="col ? col : 12">
        <v-select
          :key="f.title"
          v-if="f.selectValues"
          :items="getTitles(f.selectValues)"
          :disabled="f.disable"
          dense
          outlined
          full-width
          hide-details
          class="pa-1 ma-0"
          :append-icon="''"
          :value="
            getValue(org_selected[f.value], f, 'value')
              ? getValue(org_selected[f.value], f, 'value').title
              : ''
          "
          @input="
            (value) => {
              setSelectValue(value, f);
            }
          "
        >
          <template v-slot:label
            ><span class="pa-0 ma-0"> {{ f.title }} </span>
          </template>
        </v-select>
        <ValidationProvider
          v-else
          :key="f.value"
          :rules="f.rules ? f.rules : ''"
          :name="f.title"
          validate-on="change"
          v-slot="{ errors }"
        >
          <v-text-field
            class="ma-0 pa-1"
            :hide-details="!errors.length"
            :error-messages="errors[0]"
            type="text"
            outlined
            dense
            v-model="org_selected[f.value]"
            :disabled="f.disable"
            background-color="#20212E"
          >
            <template v-slot:label
              ><span class="pa-0 ma-0"> {{ f.title }} </span>
            </template>
          </v-text-field>
        </ValidationProvider>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    ValidationProvider,
  },
  props: ["fields", "org_selected", "col", "account"],
  // computed: {
  //   org_data() {
  //     return { ...this.org_selected, account: this.account };
  //   },
  // },
  methods: {
    getTitles(fields) {
      return fields.map((value) => value.title);
    },
    getValue(value, data, key) {
      return data.selectValues.filter((sv) => sv[key] === value)[0];
    },
    setSelectValue(value, data) {
      this.org_selected[data.value] = this.getValue(value, data, "title").value;
    },
    async loadData() {},
  },
  async created() {
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
.card {
  .v-text-field {
    padding: 10px;
  }
}
</style>
