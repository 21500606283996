<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <v-btn
          color="#20212E"
          class="elevation-5 pa-2 px-5 button-outlined"
          block
          @click="flagModal = true"
        >
          <span>New</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        class="elevation-5 ma-0 text--md rounded-0 main-table overflow-x-auto"
        dense
        fixed-header
        hide-default-footer
        height="100%"
        :header-props="{ sortIcon: 'mdi-chevron-up' }"
        :headers="header"
        :items="items"
        disable-pagination
        mobile-breakpoint="0"
        :loading="loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-col class="pa-0 ma-0" cols="auto">
              <v-menu bottom left small>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="editQuota(item)"
                    style="display: flex; flex-flow: row; gap: 5px"
                  >
                    <v-icon x-small dark>mdi-account-edit</v-icon>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="deleteItem(item)"
                    style="display: flex; flex-flow: row; gap: 5px"
                  >
                    <v-icon x-small color="red">mdi-delete</v-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-row>
    <CreateQuotaModal
      v-if="flagModal"
      :title="editData ? 'Update Quota' : 'Create Quota'"
      :customerId="customerId"
      :editData="editData"
      @close="flagModal = false"
      @updateTable="refreshTable"
    />
  </v-container>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { getQuotasByCust, deleteQuota } from "@/helpers/api/Organizations/index.js";
import { get_orders_and_quotas } from "@/helpers/api/indexv2";
import CreateQuotaModal from "@/components/Organizations/CreateQuotaModal.vue";

export default {
  components: { CreateQuotaModal },
  props: ["org_selected"],

  computed: {
    header() {
      return generateHeaderData([
        {
          align: "center",
          text: "Location",
          value: "Location",
          class: "table-header",
        },
        {
          align: "center",
          text: "Size",
          value: "Size",
          class: "table-header",
        },
        {
          align: "center",
          text: "Type",
          value: "Type",
          class: "table-header",
        },
        {
          align: "center",
          text: "quota",
          value: "quota",
          class: "table-header",
        },
        {
          align: "center",
          text: "sold",
          value: "orderSold",
          class: "table-header",
        },
        {
          align: "center",
          text: "limit",
          value: "limit",
          class: "table-header",
        },
        {
          align: "center",
          text: "",
          value: "actions",
          class: "table-header",
          width: "10px",
        },
      ]);
    },
    customerId() {
      return this.org_selected.organization_id;
    },
  },
  data() {
    return {
      items: [],
      editData: null,
      flagModal: false,
      loading: false,
    };
  },
  watch: {
    flagModal() {
      if (!this.flagModal) this.editData = null;
    },
    org_selected: {
      immediate: true,
      deep: true,
      async handler() {
        await this.refreshTable();
      },
    },
  },
  methods: {
    async refreshTable() {
      this.loading = true;
      const res = await get_orders_and_quotas(this.customerId);

      const items = res.data.quotas.map((x) => {
        let orderFiltered = res.data.orders.filter(
          (item) =>
            `${x.Location}_${x.Size}_${x.Type}` ===
            `${item.Location}_${item.Size}_${item.Type}`
        );
        const sold = orderFiltered.length ? orderFiltered[0].qtySold : 0;

        return { ...x, orderSold: sold, limit: Number(x.quota) - Number(sold) };
      });
      this.items = items;
      this.loading = false;
    },
    editQuota(item) {
      this.editData = item;
      this.flagModal = true;
    },
    async deleteItem(item) {
      this.loading = true;
      await deleteQuota(item.id);
      this.refreshTable();
    },
  },
};
</script>

<style lang="scss" scoped></style>
