<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <slot name="logo"></slot>
      </v-col>
      <v-col>
        <slot name="form"></slot>
      </v-col>
    </v-row>

    <v-row class="flex-column">
      <slot name="tabs"></slot>
    </v-row>

    <slot name="actions-buttons"></slot>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.form-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* .form-wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 20% 1fr 1fr;
  grid-template-rows: 20% 20% 60%;
  min-height: 0%;
  overflow-y: auto;
  gap: 4px;
  padding: 5px;

  .full-width {
    grid-column: 1/4;
  }
} */
</style>
