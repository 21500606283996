<template>
  <modal width="45%" :show="show" :title="title">
    <template v-slot>
      <v-row class="ma-1">
        <v-col cols="12" class="ma-0 pa-4">
          <v-text-field
            label="File Name"
            type="text"
            v-model="item.filename"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-1">
        <v-col class="ma-0 pa-4">
          <v-btn
            class="elevation-5"
            color="success"
            text
            @click="publicFile"
            block
          >
            Make the document public
          </v-btn>
        </v-col>
        <v-col class="ma-0 pa-4">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            @click="signedUrl"
            block
          >
            Get a signed URL
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-1" v-if="url">
        <v-col class="ma-0 pa-4" cols="10">
          <v-text-field
            label="URL"
            type="text"
            v-model="url"
            ref="url"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
        </v-col>
        <v-col class="ma-0 pa-4">
          <v-btn class="elevation-5" color="info" text block @click="copyText"
            >copy</v-btn
          >
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-1 pa-0">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            @click="closeModal"
            block
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { mapActions } from "vuex";
export default {
  components: {
    Modal,
  },
  props: ["show", "title", "item"],
  data() {
    return {
      url: "",
    };
  },
  methods: {
    ...mapActions(["getSignedURL", "makePublicFile"]),
    closeModal() {
      this.$emit("showModal");
    },
    copyText() {
      navigator.clipboard.writeText(this.url);
    },
    async signedUrl() {
      let response = await this.getSignedURL({ filename: this.item.filename });
      this.url = response.url;
    },
    async publicFile() {
      console.log("btn");
      let response = await this.makePublicFile({
        filename: this.item.filename,
      });
      this.url = response.url;
    },
  },
};
</script>

<style scoped></style>
