<template>
  <div class="header text-center profile ma-0 pa-0">
    <div class="file-wrapper">
      <div class="image" :style="getImageStyle"></div>

      <div
        class="span-wrapper"
        :style="[opacityHover ? { opacity: 1 } : { opacity: 0 }]"
      >
        <v-icon size="xx-large" color="primary">mdi-plus</v-icon>
        <span>Add Logo</span>
      </div>
      <input
        type="file"
        @mouseover="over"
        @mouseout="out"
        @change="getInputFile"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
export default {
  props: ["formData", "org_selected"],
  computed: {
    getImageStyle() {
      let url =
        this.org_selected.logo_filename ||
        "https://res.cloudinary.com/yalibj/image/upload/v1643116824/v/organization_tpr15c.svg";

      let opacity = this.opacityHover ? "0.3" : " 1 ";
      return {
        "background-image": `url(${url}) `,
        // "background-size": "contain",
        opacity,
      };
    },
  },
  data() {
    return {
      opacityHover: false,
    };
  },
  methods: {
    out() {
      this.opacityHover = false;
    },
    over() {
      this.opacityHover = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    async getInputFile(e) {
      const files = e.target.files;

      if (!files.length) return;

      let name = files[0].name.replaceAll(" ", "");

      this.formData.append(
        "logo",
        files[0],
        `${DateTime.now().toMillis()}${name}`,
      );

      this.org_selected.logo_filename = await this.getBase64(files[0]);
    },
  },
};
</script>

<style scoped>
.profile {
  position: relative;
  width: 100%;
  height: 100%;
}

.profile input {
  position: absolute;
  cursor: pointer;
  top: -25%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60%;
  height: 60%;
  opacity: 0;
  margin: auto;
}

.profile .span-wrapper {
  position: absolute;
  cursor: pointer;
  top: -25%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 60%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #3284f4;
  font-size: 1em;
}

.file-wrapper {
  width: 80%;
  margin: auto;
  height: 100%;
}

.image {
  height: 100%;
  width: 100%;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
