import { render, staticRenderFns } from "./QuotaTable.vue?vue&type=template&id=22845cea&scoped=true"
import script from "./QuotaTable.vue?vue&type=script&lang=js"
export * from "./QuotaTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22845cea",
  null
  
)

export default component.exports