<template>
  <modal width="45%" :show="show" :title="title">
    <template v-slot>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0 text-center d-flex justify-center align-center">
          <div v-if="!file">
            <div class="uploadFile">
              <v-icon size="5vw" class="">mdi-cloud-upload</v-icon>
              <input
                name="flat"
                type="file"
                @change="inputFile"
                id="file"
                ref="file"
              />
            </div>
          </div>
          <div v-else>
            <v-icon size="5vw">mdi-file-cloud</v-icon>
            <v-btn
              type="button"
              class="btn btn-primary removeFile"
              color="error"
              @click="removeFile"
              small
              >Remove File</v-btn
            >
          </div>
        </v-col>
        <v-col class="ma-0 pa-0" cols="8">
          <v-row class="ma-1">
            <v-col cols="12" class="ma-0 pa-2">
              <v-text-field
                label="File Name"
                type="text"
                v-model="name"
                outlined
                dense
                hide-details
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="ma-0 pa-2">
              <v-text-field
                label="Created by"
                type="text"
                v-model="user.name"
                outlined
                dense
                disabled
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="ma-0 pa-2">
              <v-text-field
                label="Date created"
                type="text"
                v-model="date"
                outlined
                dense
                disabled
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="ma-0 pa-2" v-if="!masterContract">
              <v-select
                :items="[
                  { text: 'ORGANIZATION_INFO', value: 'ORGANIZATION_INFO' },
                  { text: 'Master Contract', value: 'masterContract' },
                ]"
                item-text="text"
                item-value="value"
                v-model="type"
                dense
                hide-details
                outlined
                :append-icon="''"
              ></v-select>
              <!-- style="width: fit-content" -->
            </v-col>
            <v-col class="ma-0 pa-2 d-flex" v-if="!masterContract">
              <p class="ma-0 pa-2 theme-dark">Permissions:</p>
              <v-checkbox
                v-model="selected"
                label="Write"
                value="write"
                class="ma-1"
                ><span>Write</span></v-checkbox
              >
              <v-checkbox
                v-model="selected"
                label="Read"
                value="read"
                class="ma-1"
                ><span>Read</span></v-checkbox
              >
            </v-col>
          </v-row>
          <v-row class="ma-1"> </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-row class="ma-0 pa-0" justify="center">
        <v-col class="ma-1 pa-0">
          <v-btn
            class="elevation-5"
            color="success"
            text
            @click="uploadFileBtn"
            block
          >
            Upload
          </v-btn>
        </v-col>
        <v-col class="ma-1 pa-0">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            @click="closeModal"
            block
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import { DateTime } from "luxon";
import Modal from "@/layout/Modal.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    Modal,
  },
  props: ["show", "title", "organization_id", "details", "masterContract"],
  data() {
    return {
      documents: [],
      file: "",
      name: "",
      date: "",
      userCreated: "",
      type: "ORGANIZATION_INFO",
      DateTime,
      selected: ["write", "read"],
    };
  },
  methods: {
    ...mapActions(["uploadFile", "uploadMasterContract"]),
    closeModal() {
      this.removeFile();
      this.$emit("showModal");
    },
    removeFile() {
      this.file = "";
      this.name = "";
      this.date = "";
    },
    inputFile(e) {
      var files = e.target.files || e.dataTransfer.files;
      let file = e.target.files[0];

      if (!files.length) {
        return;
      }

      this.file = file;
      this.name = file.name;
      this.date = DateTime.fromISO(new Date().toISOString()).toFormat(
        "LL/dd/yyyy",
      );
      if (
        this.extension === "php" ||
        this.extension === "exe" ||
        this.extension === "bat"
      ) {
        this.removeFile();
        alert("invalid format");
      }
    },
    async uploadFileBtn() {
      let formData = new FormData();
      let response;
      let docType;
      let sourceid;
      let sourceType;
      if (this.masterContract) {
        this.$emit("uploadMasterContract", { file: this.file, formData });

        this.removeFile();
      } else {
        switch (this.type) {
          case "ORGANIZATION_INFO":
            docType = "ORGANIZATION_DOC";
            sourceid = null;
            sourceType = "ORGANIZATION_INFO";
            if (this.details) {
              docType = `SALE${this.details[0].docType.toUpperCase()}`;
              sourceid = this.details[0].docNo;
              sourceType = "FILEATTACH";
            }

            formData.append("file", this.file);
            formData.append("docType", docType);
            formData.append("sourceType", sourceType);
            formData.append("sourceid", sourceid);
            formData.append("permissions", this.selected.join());
            formData.append("organization_id", this.organization_id);
            response = await this.uploadFile(formData);

            if (response) {
              this.removeFile();
              this.$emit("showModal");
            }
            break;

          case "masterContract":
            docType = "ORGANIZATION_DOC";
            sourceid = null;
            sourceType = "MASTER_CONTRACT";
            formData.append("file", this.file);
            formData.append("docType", docType);
            formData.append("sourceType", sourceType);
            formData.append("sourceid", sourceid);
            formData.append("permissions", this.selected.join());
            formData.append("organization_id", this.organization_id);
            response = await this.uploadFile(formData);

            if (response) {
              this.removeFile();
              this.$emit("showModal");
            }
            break;
        }
      }
    },
  },
  computed: {
    ...mapState(["user"]),
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
  },
};
</script>

<style scoped>
.uploadFile {
  height: 30%;
  position: relative;
}

.uploadFile:hover {
  border: 1px solid #2e94c4;
}

.uploadFile input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
@media only screen and (min-width: 1264px) {
  .upload-top {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1500px) {
  .upload-top {
    max-width: 60%;
  }
}
</style>
