<template>
  <modal width="300px" :title="title" show="true">
    <template v-slot>
      <v-row class="ma-0 px-4 py-2">
        <v-col class="ma-0 py-2" cols="12">
          <span class="text-subtitle-1 font-weight-bold py-1">Location</span>
          <v-autocomplete
            v-model="data['Location']"
            @input="getTypes"
            :items="arrLocations"
            color="white"
            dense
            solo
            background-color="#20212E"
            hide-details
            class="rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center"
            item-text="code"
            item-value="code"
            return-object
          >
          </v-autocomplete>
        </v-col>
        <v-col class="ma-0 py-2" cols="12">
          <span class="text-subtitle-1 font-weight-bold py-1">Type</span>
          <v-autocomplete
            v-model="data['Type']"
            :items="types"
            @input="getSizes"
            color="white"
            dense
            solo
            background-color="#20212E"
            hide-details
            class="rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center"
            item-text="code"
            item-value="code"
            return-object
          >
          </v-autocomplete>
        </v-col>
        <v-col class="ma-0 py-2" cols="12">
          <span class="text-subtitle-1 font-weight-bold py-1">Size</span>
          <v-autocomplete
            v-model="data['Size']"
            :items="sizes"
            color="white"
            dense
            solo
            background-color="#20212E"
            hide-details
            class="rounded-0 lighten-2 pa-0 ma-0 elevation-5 text-center"
            item-text="code"
            item-value="code"
            return-object
          >
          </v-autocomplete>
        </v-col>
        <v-col class="ma-0 py-2" cols="12">
          <span class="text-subtitle-1 font-weight-bold py-1">Quota</span>
          <v-text-field
            type="text"
            outlined
            dense
            hide-details
            v-model="data['quota']"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-1 pa-0">
          <v-btn class="elevation-5" color="success" text block @click="saveGBQuota">
            {{ editData ? "Update" : "Create" }}
          </v-btn>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-1 pa-0">
          <v-btn class="elevation-5" color="primary" text block @click="$emit('close')">
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { mapState, mapActions } from "vuex";
import { getInventoryQtySummCompact } from "@/helpers/api/indexv2";
export default {
  components: {
    Modal,
  },
  props: ["title", "customerId", "editData"],
  async created() {
    const { data } = await getInventoryQtySummCompact();
    this.data = { ...this.editData };
    const dataLocation = data.reduce((acc, item) => {
      acc[item.Depot] = acc[item.Depot] ? [...acc[item.Depot], item] : [item];
      return acc;
    }, {});
    this.locations = dataLocation;
    this.data.Location = this.editData ? this.editData.Location : this.arrLocations[0];
    this.getTypes();
    this.getSizes();
  },
  computed: {
    arrLocations() {
      return Object.keys(this.locations);
    },
    location() {
      return this.data.location;
    },
  },
  data() {
    return {
      data: {},
      locations: [],
      sizes: [],
      types: [],
    };
  },
  methods: {
    ...mapActions({
      createGbQuota: "moduleOrganizations/createGbQuota",
    }),
    async saveGBQuota() {
      const data = { ...this.data, customerId: this.customerId };

      await this.createGbQuota({ data, flag: this.editData === null });
      this.$emit("updateTable");
      this.$emit("close");
    },
    getTypes() {
      const depot = this.data.Location;

      if (depot && depot.length) {
        this.types = this.locations[depot].reduce((acc, item) => {
          if (!acc.includes(item.Type)) acc.push(item.Type);

          return acc;
        }, []);
      }
      if (this.editData) {
        this.data.Type = this.editData.Type;
        this.data.Size = this.editData.Size;
      } else {
        this.data.Type = [];
        this.data.Size = [];
      }
    },
    getSizes() {
      const depot = this.data.Location;
      const type = this.data.Type;
      const locationsTypes = this.locations[depot].filter((x) => x.Type === type);

      if (depot && depot.length) {
        this.sizes = locationsTypes.reduce((acc, item) => {
          if (!acc.includes(item.Size)) acc.push(item.Size);

          return acc;
        }, []);
      }
      if (this.editData) {
        this.data.Size = this.editData.Size;
      } else {
        this.data.Size = [];
      }
    },
  },
};
</script>

<style scoped></style>
