var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.title === 'documents')?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"elevation-5 pa-2 px-5 button-outlined",attrs:{"color":"#20212E","block":""},on:{"click":_vm.showUploadDocModal}},[_c('span',[_vm._v("Upload")])])],1):(_vm.title === 'customfields')?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"elevation-5 pa-2 px-5 button-outlined",attrs:{"color":"#20212E","block":""},on:{"click":_vm.showNewCustomFieldModal}},[_c('span',[_vm._v("New")])])],1):(
        ![
          'quotes and open orders',
          'completed orders',
          'history orders',
          'transaction history',
        ].includes(_vm.title)
      )?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"elevation-5 pa-2 px-5 button-outlined",attrs:{"color":"#20212E","block":""},on:{"click":_vm.addNewContact}},[_c('span',[_vm._v(" New")])])],1):(['transaction history'].includes(_vm.title))?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"elevation-5 pa-2 px-5 button-outlined",attrs:{"color":"#20212E","block":""},on:{"click":_vm.exportTable}},[_c('span',[_vm._v(" Export")])])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticStyle:{"height":"100%","max-height":"45vh","overflow-y":"auto"},attrs:{"cols":"12"}},[_c('CardTable',{attrs:{"items":_vm.records,"headerTable":_vm.headerTable,"title":_vm.title},on:{"action":_vm.action}})],1)],1),_c('DocumentsModal',{attrs:{"show":_vm.showModal,"width":100,"title":"Upload documents","organization_id":_vm.org_selected.organization_id},on:{"showModal":_vm.showUploadDocModal}}),_c('custom-field-modal',{attrs:{"show":_vm.showCFModal,"width":100,"title":"Create new custom field","organization_id":_vm.org_selected.organization_id},on:{"showModal":_vm.showNewCustomFieldModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }